* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;


  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  ::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  .sidebar::-webkit-scrollbar {
    width: 0;
  }

  .sidebar {
    &:hover::-webkit-scrollbar {
      width: 8px;
    }
  }
}

// fix firefox Montserrat-Bold issue
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f5f9ff;
}

//todo: use otf file for Montserrat-Light
@font-face {
  font-family: 'Montserrat-Light';
  src: url('../assets/fonts/Montserrat-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../assets/fonts/Montserrat-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../assets/fonts/Montserrat-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../assets/fonts/Montserrat-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../assets/fonts/Montserrat-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SharpSansNo1-Bold';
  src: url('../assets/fonts/SharpSansNo1-Bold.otf') format('opentype');
}
