.swagger-ui .opblock.opblock-post {
    border-color: #38b1df;
    background: #dff6ff;
}
.swagger-ui .opblock.opblock-post .opblock-summary-method {
    background: #38b1df;
}
.swagger-ui .opblock.opblock-post .opblock-summary {
    border-color: #38b1df;
}

.swagger-ui .btn.authorize svg {
    fill: #38b1df;
}

.swagger-ui .btn.authorize {
    color: #38b1df;
    border-color: #38b1df;
}

.swagger-ui .opblock.opblock-post .tab-header .tab-item.active h4 span:after {
    background: #38b1df;
}

.swagger-ui .response-control-media-type__accept-message {
    color: #38b1df;
}

.swagger-ui .response-control-media-type--accept-controller select {
    border-color: #38b1df;
}
